import React from 'react';

import { ColumnDefinition } from 'src/components/admin-table/hooks';
import { CoachProfilePanel } from 'src/components/coaches/types';
import { defaultProgrammesColumnsConfig } from 'src/components/organisations/organisation-profile/panels/common/scheme-courses/config';
import SchemeCourses from 'src/components/organisations/organisation-profile/panels/common/scheme-courses/scheme-courses';
import { SCHEME_SUBCATEGORY } from 'src/utils/constants/classic';
import { TABLE_IDS } from 'src/utils/constants/tables';

interface Props extends CoachProfilePanel {
  columnsConfig?: ColumnDefinition[];
}

const Programmes = ({
  coachId,
  coachUserId,
  transKeyTitle,
  transKeyQueryError = 'coachesModule.queryErrors.getCoachProgrammes',
  columnsConfig = defaultProgrammesColumnsConfig,
}: Props) => {
  return (
    <SchemeCourses
      id={coachId}
      userId={coachUserId}
      schemeSubCategory={SCHEME_SUBCATEGORY.GROUP_COACHING}
      tableId={TABLE_IDS.COACH_PROFILE_PROGRAMMES}
      transKeys={{
        title: transKeyTitle,
        categoryFilterDefaultLabel: 'any programme',
        queryError: transKeyQueryError,
      }}
      columnsConfig={columnsConfig}
    />
  );
};

export default Programmes;
