import { ModuleID } from 'src/config/types';
import { PropsFrom } from 'src/types';

import OrganisationsPanel from './panels/organisations/organisations';
import ProgrammesPanel from './panels/programmes/programmes';
import { CoachProfileProps } from './types';

export const COACH_PROFILE_PANEL_MAP = {
  organisations: OrganisationsPanel,
  programmes: ProgrammesPanel,
} as const;

export type CoachProfilePanelType = keyof typeof COACH_PROFILE_PANEL_MAP;

export type CoachPanel<T extends CoachProfilePanelType> =
  | T
  | {
      type: T;
      props: Omit<PropsFrom<(typeof COACH_PROFILE_PANEL_MAP)[T]>, 'coachId'>;
      modulesRequired?: ModuleID[];
    };

export type TypedCoachPanel = CoachPanel<'organisations'> | CoachPanel<'programmes'>;

export type Tab = {
  key: string;
  transKeyLabel?: string;
  panels: TypedCoachPanel[];
  modulesRequired?: ModuleID[];
};

export const defaultConfig: Omit<Required<CoachProfileProps>, 'id'> = {
  tabsConfig: [
    {
      key: 'organisations',
      panels: ['organisations'],
    },
    {
      key: 'programmes',
      panels: ['programmes'],
    },
  ],
  headerConfig: {
    accessorFirstName: 'FirstName',
    accessorLastName: 'LastName',
    headingInfo: [
      {
        key: 'BirthDate',
        transformer: {
          type: 'date',
          args: {
            accessor: 'BirthDate',
            dateFormat: { day: '2-digit', month: 'short', year: 'numeric' },
          },
        },
      },
      {
        key: 'Age',
        transformer: {
          type: 'dataTranslation',
          args: {
            accessor: 'Age',
            transKey: 'age xx',
            interpolationKey: 'age',
          },
        },
        fallback: 'unknown',
      },
      { key: 'Gender' },
    ],
    extraInfo: [
      {
        key: 'Roles',
        transLabel: 'Roles',
        transformer: {
          type: 'list',
          args: {
            accessor: 'ContactRoles',
            accessorProperty: 'Name',
            dedup: true,
            maxLength: 1,
          },
        },
      },
      {
        key: 'Registratered',
        transLabel: 'Registered',
        transformer: {
          type: 'boolean',
          args: {
            accessor: 'Registered',
            truthy: 'yes',
            falsy: 'no',
            translate: true,
          },
        },
      },
    ],
  },
};
