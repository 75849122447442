import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { classicRestClient } from 'src/apollo/client';
import { transformers } from 'src/components/data-transformers/transformers';
import { accessData } from 'src/components/data-transformers/utils';
import { Props as HeaderProps } from 'src/components/profile/header/header-individual/header-individual';
import { ProfileTab } from 'src/components/profile/types';
import { getRootProviderId } from 'src/config/config';
import { GET_ORG_COACHES } from 'src/queries/REST/organisations';
import { GetOrganisationCoaches } from 'src/types/classic-queries';
import ROUTES from 'src/utils/routes';

import TabPanels from './coach-profile/tabs/tab-panels';
import { CoachProfileHeaderConfig, TabsConfig } from './coach-profile/types';
import { getVisibleTabs } from './coach-profile/utils';
import { CoachesTableItem } from './types';

export const useCoachProfile = ({
  id,
  userId,
  shouldSkip = false,
  redirectOnNoData = false,
}: {
  id?: string;
  userId?: string;
  shouldSkip?: boolean;
  redirectOnNoData?: boolean;
}) => {
  const tenantId = getRootProviderId();
  const idParam = userId ? { UserID: userId } : { VenueContactID: id };
  const { data, loading, error } = useQuery<GetOrganisationCoaches>(GET_ORG_COACHES, {
    client: classicRestClient,
    variables: {
      input: {
        ParentRegionId: tenantId,
        QueryParametersPaged: { Limit: 1, Offset: 0, Filters: [], Sorts: [] },
        ...idParam,
      },
    },
    skip: shouldSkip,
    onCompleted: (data) => {
      if (!data.organisationCoaches.Items?.length && redirectOnNoData) navigate(ROUTES.NOT_FOUND);
    },
  });

  return {
    data: data?.organisationCoaches?.Items?.[0],
    loading,
    error,
  };
};

export const useCoachProfileTabs = ({
  coach,
  config,
}: {
  coach?: CoachesTableItem;
  config: TabsConfig;
}): ProfileTab[] => {
  const { t } = useTranslation();
  return useMemo(() => {
    if (!coach) return [];

    return getVisibleTabs(config).map(({ key, transKeyLabel, panels }) => ({
      key,
      label: t(transKeyLabel ?? key),
      content: <TabPanels key={key} panels={panels} coachId={coach.id} coachUserId={coach.UserID} />,
    }));
  }, [t, config, coach]);
};

const transformData = (data, { t, key, transformer, fallback = 'n/a' }) => {
  const Transformer = transformers[transformer?.type ?? 'default'];
  const tranformerProps = { t, fallback, args: transformer?.args ?? { accessor: key } };
  return <Transformer {...(tranformerProps as any)} data={data} key={key} />;
};

export const useCoachProfileHeader = ({
  coach,
  config,
}: {
  coach?: CoachesTableItem;
  config: CoachProfileHeaderConfig;
}): HeaderProps | undefined => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!coach) return undefined;

    const { accessorFirstName, accessorLastName, headingInfo, headingAccessory, extraInfo } = config;

    return {
      firstName: accessData(coach, accessorFirstName) || '',
      lastName: accessData(coach, accessorLastName) || '',
      headingAccessory:
        headingAccessory &&
        transformData(coach, {
          t,
          key: headingAccessory.key,
          transformer: headingAccessory.transformer,
          fallback: headingAccessory.fallback,
        }),
      headingInfo: headingInfo.map(({ key, transformer, fallback }) =>
        transformData(coach, { t, key, transformer, fallback }),
      ),
      extraInfo: extraInfo.map(({ key, transLabel, transformer, fallback = 'n/a' }) => {
        const Transformer = transformers[transformer?.type ?? 'default'];
        const tranformerProps = { t, fallback, args: transformer?.args ?? { accessor: key } };
        return {
          label: t(transLabel),
          value: <Transformer {...(tranformerProps as any)} data={coach} />,
        };
      }),
    };
  }, [t, coach, config]);
};
