import React, { useMemo } from 'react';

import { RouteComponentProps } from '@reach/router';
import { getClientProps } from 'src/components/coaches/client-props';
import CoachProfile from 'src/components/coaches/coach-profile/coach-profile';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

type CoachProfilePageProps = { id?: string } & RouteComponentProps;

export const CoachProfilePage: React.FC<CoachProfilePageProps> = ({ id }) => {
  const clientProps = useMemo(() => getClientProps()?.profilePage ?? {}, []);
  return (
    <Layout>
      <SEO title="Coach Profile" />
      <PrivateRoute>
        <CoachProfile {...clientProps} id={id as string} />
      </PrivateRoute>
    </Layout>
  );
};

export default CoachProfilePage;
