import React from 'react';

import { COACH_PROFILE_PANEL_MAP, TypedCoachPanel } from 'src/components/coaches/coach-profile/config';

const TabPanels = ({
  panels,
  coachId,
  coachUserId,
}: {
  panels: TypedCoachPanel[];
  coachId: string;
  coachUserId?: string;
}) => {
  return (
    <>
      {panels.map((panel, index) => {
        let Panel, props, key;
        if (typeof panel === 'string') {
          Panel = COACH_PROFILE_PANEL_MAP[panel];
          props = {};
          key = `${panel}-${index}`;
        } else {
          Panel = COACH_PROFILE_PANEL_MAP[panel.type];
          props = panel.props;
          key = `${panel.type}-${index}`;
        }

        if (!Panel) return null;

        return <Panel key={key} {...props} coachId={coachId} coachUserId={coachUserId} />;
      })}
    </>
  );
};

export default TabPanels;
