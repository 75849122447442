import { useMemo } from 'react';

import { CoachesTableItem } from 'src/components/coaches/types';

import { CoachesOrganisationsTableItem } from './types';

export const useCoachOrganisations = (coach?: CoachesTableItem) => {
  return useMemo(() => {
    if (!coach?.ContactRoles) return [];
    const orgMap: Record<string, CoachesOrganisationsTableItem> = {};

    coach.ContactRoles.forEach((role) => {
      const id = role.Venue.ID;

      if (orgMap[id]) {
        orgMap[id].roles.push(role.Name);
      } else {
        orgMap[id] = {
          id,
          name: role.Venue.Name,
          type: 'Club',
          region: coach.ContactRegions.find(({ ID }) => ID === role.Venue.RegionID),
          roles: [role.Name],
        };
      }
    });

    return Object.values(orgMap);
  }, [coach]);
};
