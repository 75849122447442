import React from 'react';

import { useTranslation } from 'react-i18next';
import AdminTable from 'src/components/admin-table/admin-table';
import { ColumnDefinition, useColumnDefinitions } from 'src/components/admin-table/hooks';
import { useCoachProfile } from 'src/components/coaches/hooks';
import { CoachProfilePanel } from 'src/components/coaches/types';
import * as panelStyles from 'src/components/organisations/organisation-profile/panels/panels.module.less';
import Panel from 'src/components/panel/panel';
import { PageReferrer } from 'src/types';
import ROUTES from 'src/utils/routes';

import { useCoachOrganisations } from './hooks';

const defaultColumnsConfig: ColumnDefinition[] = [
  {
    header: 'organisation name',
    key: 'name',
    transformer: {
      type: 'link',
      args: {
        accessor: 'name',
        urlTemplate: `${ROUTES.MANAGE_ORGANISATIONS}/{{id}}?referrer=${PageReferrer.COACHES}`,
      },
    },
    sort: false,
  },
  {
    header: 'type',
    key: 'type',
    sort: false,
  },
  {
    header: 'county',
    key: 'region.Name',
    sort: false,
  },
  {
    key: 'roles',
    header: 'roles',
    transformer: {
      type: 'list',
      args: {
        accessor: 'roles',
        dedup: true,
      },
    },
    sort: false,
  },
];

interface Props extends CoachProfilePanel {
  columnsConfig?: ColumnDefinition[];
}

const Organisations = ({
  coachId,
  coachUserId,
  transKeyTitle,
  transKeyQueryError = 'coachesModule.queryErrors.getCoachOrganisations',
  columnsConfig = defaultColumnsConfig,
}: Props) => {
  const { t } = useTranslation();
  const { data, loading, error } = useCoachProfile({ id: coachId, userId: coachUserId });
  const organisations = useCoachOrganisations(data);
  const columns = useColumnDefinitions(columnsConfig);

  return (
    <Panel
      title={transKeyTitle && t(transKeyTitle)}
      spacing={{ margins: { md: 'top' } }}
      classNames={{ container: panelStyles.container }}
    >
      <AdminTable
        data={organisations}
        columns={columns}
        loading={loading}
        error={error ? t(transKeyQueryError) : undefined}
      />
    </Panel>
  );
};

export default Organisations;
