import React, { useState } from 'react';

import { useLocation, WindowLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useCoachProfile, useCoachProfileHeader, useCoachProfileTabs } from 'src/components/coaches/hooks';
import { CoachesTableItem } from 'src/components/coaches/types';
import ErrorPanel from 'src/components/error-panel/error-panel';
import { Props as HeaderProps } from 'src/components/profile/header/header-individual/header-individual';
import Profile from 'src/components/profile/profile';
import Spinner from 'src/components/spinner/spinner';
import { PageReferrer } from 'src/types';
import { StringParam, useQueryParams } from 'use-query-params';

import { defaultConfig } from './config';
import { CoachProfileProps } from './types';
import { getCurrentPageName } from './utils';

const CoachProfile = ({
  id,
  tabsConfig = defaultConfig.tabsConfig,
  headerConfig = defaultConfig.headerConfig,
}: CoachProfileProps) => {
  const { t } = useTranslation();
  const [query] = useQueryParams({ userId: StringParam });
  const page = useLocation() as WindowLocation<CoachesTableItem>;
  const [prefetchedCoach] = useState(page.state?.id && page.state.id === id ? page.state : undefined);
  const { data, loading, error } = useCoachProfile({
    id,
    userId: query.userId ?? undefined,
    shouldSkip: !!prefetchedCoach || !id,
    redirectOnNoData: true,
  });
  const coach = prefetchedCoach ?? data;
  const tabs = useCoachProfileTabs({ config: tabsConfig, coach });
  const headerProps = useCoachProfileHeader({
    coach,
    config: headerConfig,
  });

  if (error) return <ErrorPanel subtitle={t('coachesModule.queryErrors.getCoachProfile')} />;

  if (loading || !coach) return <Spinner fullScreen />;

  return (
    <Profile<'individual'>
      currentPageName={getCurrentPageName(coach, headerConfig)}
      defaultPageReferrer={PageReferrer.COACHES}
      header={{
        type: 'individual',
        props: headerProps as HeaderProps,
      }}
      tabs={tabs}
    />
  );
};

export default CoachProfile;
