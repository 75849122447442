import { CoachesTableItem } from 'src/components/coaches/types';
import { accessData } from 'src/components/data-transformers/utils';
import { hasRequiredModulesForElement } from 'src/utils/module-related-visibility';
import { getFormattedFullName } from 'src/utils/text';

import { Tab } from './config';
import { CoachProfileHeaderConfig } from './types';

export const getVisibleTabs = (tabs: Tab[]) => {
  return tabs
    .map((tab) => {
      if (!hasRequiredModulesForElement(tab)) return { ...tab, panels: [] };

      const visiblePanels = [...tab.panels].filter(
        (panel) => typeof panel === 'string' || hasRequiredModulesForElement(panel),
      );

      return {
        ...tab,
        panels: visiblePanels,
      };
    })
    .filter((tab) => tab.panels.length);
};

export const getCurrentPageName = (coach: CoachesTableItem, config: CoachProfileHeaderConfig) => {
  const firstName = accessData(coach, config.accessorFirstName);
  const lastName = accessData(coach, config.accessorLastName);
  return getFormattedFullName([firstName, lastName]);
};
